<template>
  <tr
    :class="['product flex-table row']"
    role="rowgroup"
  >

    <td class="flex-row first" role="cell">
      <!-- product:image START -->
      <div class="image" v-if="false">
        <img
          v-if="product.articleData.images.filter(image => image.includes('500x500'))"
          :src="product.articleData.images.filter(image => image.includes('500x500'))"
          :alt="product.name"
        />
        <img
          v-else
          :src="product.articleData.images[0]"
          :alt="product.name"
        />
        <span
          class="f-like-image-loading-product"
          v-if="!product.articleData.images"
        ></span>
      </div>
      <div class="image image-blue">
        <img
          :alt="product.name"
        />
      </div>
      <!-- product:image END -->

      <!-- product:headline START -->
      <h2 v-html="product.articleData.shortName"></h2>
      <!-- product:headline END -->
    </td>

    <!-- product:price START -->
    <td class="flex-row" role="cell">
      <div class="price">
        <span
          v-if="product.price && product.quantity"
          v-html="`${product.currencySymbol} ${priceFormat(product.singlePrice)}`"
        ></span>
        <span
          v-if="product.isReduced === 1"
          v-html="` / <span class='reduced'>${product.currencySymbol}
            ${priceFormat(product.ordinarySinglePrice)}
          </span>`"
        ></span>
      </div>
    </td>
    <!-- product:price END -->

    <!-- product:quantity:price START -->
    <td class="flex-row" role="cell">
      <div class="quantity-price">
        <transition name="change-price">
          <span class="price" v-html="`${product.currencySymbol}
          ${priceFormat(product.price)}`"></span>
        </transition>
      </div>
    </td>
    <!-- product:quantity:price END -->

    <!-- product:actions START -->
    <td class="flex-row" role="cell">
      <div class="actions" v-if="type !== 'summary'">
        <button
          v-if="product.isUniqueLine === 1 && product.isLocked === 1"
          v-on:click="decrease(product)"
          class="btn"
        ></button>
        <button
          v-else-if="product.quantity > 1 && product.isUniqueLine === 0"
          v-on:click="decrease(product)"
          class="btn remove"
        >-</button>
        <button
          v-else-if="product.quantity === 1 && product.isUniqueLine === 1"
          v-on:click="decrease(product)"
          class="btn delete"
        ></button>
        <button
          v-else
          v-on:click="decrease(product)"
          class="btn delete"
        ></button>

        <transition name="scale" mode="out-in">
          <div :key="product.quantity" class="number scale-animation">
            <span v-html="product.quantity"></span>
          </div>
        </transition>
        <button
          v-if="product.isUniqueLine === 1 && product.isLocked === 1"
          class="btn"
        ></button>
        <button
          v-else-if="product.isUniqueLine === 1"
          class="btn"
        ></button>
        <button
          v-else-if="product.quantity >= 99"
          class="btn"
        ></button>
        <button
          v-else
          v-on:click="increase(product)"
          class="btn add"
        >+</button>

      </div>
      <div
        :class="[
          'actions',
          { 'only-quantity' : type === 'summary' }
        ]"
        v-else
      >
        <button
          class="btn"
        ></button>

        <transition name="scale" mode="out-in">
          <div
            :key="product.quantity"
            :class="[
              'number scale-animation',
              { 'only-quantity' : type === 'summary' }
            ]"
          >
            <span v-html="product.quantity"></span>
          </div>
        </transition>

        <button
          class="btn"
        ></button>

      </div>
    </td>
    <!-- product:actions END -->

  </tr>
</template>

<script>
export default {
  name: 'product',
  props: {
    product: {
      type: Object,
    },

    increase: {
      type: Function,
      default: () => null,
    },

    decrease: {
      type: Function,
      default: () => null,
    },

    priceFormat: {
      type: Function,
    },
    type: {
      type: String,
    },
  },
};
</script>
